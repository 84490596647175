// -- VARIABLES -- //
$primary: #536dfe;
$primary-light: #6d83ff;
$text-heading: #ffffff;
$text-body: #f2f2f2;
$bg-dark: #2a2e35;
$bg-darker: #12181b;
$bg-light: #2e343d;
$max-width: 75rem;


//--JS Export--//
:export {
    primary: $primary;
    textHeading: $text-heading;
    textBody: $text-body;
    bgDark: $bg-dark;
    bgDarker: $bg-darker;
    bgLight: $bg-light;
}

%entry-top {
    transition: all 0.7s ease-out;
    transform: translateY(-60px);
    visibility: hidden;
    opacity: 0;
}

%entry-right {
    transition: all 0.7s ease-out;
    transform: translateX(60px);
    visibility: hidden;
    opacity: 0;
}

%entry-bottom {
    transition: all 0.7s ease-out;
    transform: translateY(60px);
    visibility: hidden;
    opacity: 0;
}

%entry-left {
    transition: all 0.7s ease-out;
    transform: translateX(-60px);
    visibility: hidden;
    opacity: 0;
}