@import '../../scss-styles/styles.module.scss';

.heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    
    .title {
        color: $text-body;
        font-size: 3rem;
        margin-bottom: .8rem;

        @media (max-width: 400px) {
            font-size: 2rem;
        }
    }
    
    .underline {
        border-top: .3rem solid $primary;
        width: 6rem;
    }
}