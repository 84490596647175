@import '../../scss-styles/styles.module.scss';

.navbar {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    transition: background .5s ease-in-out;
    z-index: 10;
    
    .navbar__container {
        width: 90%;
        max-width: $max-width;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 2rem;
        visibility: hidden;
        opacity: 0;
        transition: all 1s ease-out;

        @media (max-width: 400px) {
            height: 60px;
        }

        .navbar__logo {
            color: $primary;
            font-size: 1.5rem;
            cursor: pointer;
            transition: color .3s ease-out;

            @media (max-width: 400px) {
                font-size: 1rem;
            }

            &:hover {
                color: white;
            }
        }
    
        .navbar__tabs {
            display: flex;
            justify-content: space-around;
            align-items: center;
        }

        .hamburger__container {
            position: relative;

            .toggler {
                position: absolute;
                height: 60px;
                width: 60px;
                opacity: 0;
                z-index: 10;

                &:hover {
                    cursor: pointer;
                }
            }

            .hamburger {
                top: 0;
                left: 0;
                height: 60px;
                width: 60px;
                background: inherit;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;

                div {
                    position: relative;
                    margin: 15px;
                    height: 2px;
                    width: 100%;
                    background: #fff;
                    transition: 0.5s ease;
                }

                div:before, div:after {
                    content: '';
                    position: absolute;
                    top: -10px;
                    width: 100%;
                    height: 2px;
                    background: inherit
                }

                div:after {
                    top: 10px;
                }
            }

            .toggler:checked + .hamburger > div {
                transform: rotate(135deg);
            }

            .toggler:checked + .hamburger > div:before {
                top: 0;
                transform: rotate(275deg);
            }
            
            .toggler:checked + .hamburger > div:after {
                display: none;
            }

        }

        .navbar__tabs__list {
            background-color: $bg-darker;
            position: fixed;
            top: 4rem;
            right: 2.5rem;
            transition: all .4s ease-out;
        }
    }

}