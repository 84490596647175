@import '../../scss-styles/styles.module.scss';

.tab {
  color: $text-body;
  list-style: none;
  padding: 1rem 0;
  margin: 0 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  transition: color 0.3s ease;
  border-bottom: 2px solid none;

  &:hover {
    cursor: pointer;
    color: $primary;

    .underline {
        width: 1.5rem;
        visibility: visible;
    }
  }

  .underline {
      position: relative;
      top: .4em;
      border-top: .1rem solid $primary;
      visibility: hidden;
      width: 0;
      height: 0;
      transition: width .3s ease-out;
      
  }
}
