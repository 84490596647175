.button {
    font-weight: bold;
    font-size: 1.3rem;
    height: 4rem;
    width: 15rem;
    border-radius: .3rem;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;

    &:hover {
        cursor: pointer;
    }
}