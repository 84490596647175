@import "../../scss-styles/styles.module.scss";

.contact__section {
  width: 100%;
  background-color: $bg-light;
  padding-top: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  @media (max-width: 900px) {
    padding-top: 5rem;
  }

  .contact__heading__container {
    @extend %entry-top;

    .contact__heading {
      margin-bottom: 3rem;

      .title {
        @media (max-width: 400px) {
          font-size: 2rem;
        }
      }
    }
  }

  .contact__content {
    max-width: $max-width;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-bottom: 5rem;

    @media (max-width: 400px) {
      justify-content: center;
    }

    .contact__form__container {
      min-width: 70%;
      @extend %entry-left;

      @media (max-width: 900px) {
        width: 100%;
      }

      .contact__form {
        display: grid;
        margin-bottom: 2rem;
        grid-template-areas:
          "name name"
          "phone email"
          "message message";
        grid-gap: 2rem;

        @media (max-width: 900px) {
          grid-template-areas:
            "name name"
            "phone email"
            "message message";
          grid-gap: 1rem;
          padding: 0 1rem;
        }

        @media (max-width: 600px) {
          grid-template-areas:
            "name"
            "phone"
            "email"
            "message";
          grid-gap: 1rem;
          padding: 0 1rem;
        }

        .name__container {
          grid-area: name;
          display: flex;
          flex-direction: column;
        }

        .phone__container {
          grid-area: phone;
          display: flex;
          flex-direction: column;
        }

        .email__container {
          grid-area: email;
          display: flex;
          flex-direction: column;
        }

        .message__container {
          grid-area: message;
          display: flex;
          flex-direction: column;
        }

        label {
          color: $text-body;
          margin-bottom: 1rem;
        }

        input {
          background-color: #414a57;
          outline: none;
          border: 1px solid #414a57;
          padding: 1rem;
          color: $text-body;
          width: 100%;

          &::placeholder {
            color: $text-body;
          }
        }

        textarea {
          background-color: #414a57;
          color: $text-body;
          padding: 1rem;
          font-family: inherit;
          outline: none;
          border: none;
          width: 100%;

          &::placeholder {
            color: $text-body;
          }
        }
      }

      .contact__button {
        width: 11rem;
        height: 3.5rem;
        font-weight: bold;
        font-size: 1.3rem;
        border-radius: 0.3rem;
        transition-property: all;
        transition-duration: 0.3s;
        transition-timing-function: ease;

        @media (max-width: 400px) {
          font-size: 1rem;
          width: 10rem;
          height: 3rem;
        }
      }

      .message__sent__conatiner {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2.5rem;
        background-color: #dff2bf;
        transition: all 0.3s ease-out;
        border-radius: 1rem;

        .fa-check-circle {
          color: green;
          margin-right: 1rem;
          font-size: 1.2rem;
        }

        .message__sent {
          color: green;
          font-size: 1.5rem;
        }
      }
    }

    .social__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @extend %entry-top;

      h4 {
        color: $text-heading;
        font-size: 2rem;
        margin-bottom: 1.5rem;
      }

      .social__icons {
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          i {
            color: #94a0af;
            margin: 0 1.5rem;
            font-size: 3rem;
            transition: all 0.3s ease-out;

            @media (max-width: 400px) {
              font-size: 2rem;
            }

            &:hover {
              transform: scale(1.2);
            }
          }

          .fa-facebook-square {
            color: $primary-light;
          }

          .fa-github {
            color: $primary-light;
          }

          .fa-twitter {
            color: $primary-light;
          }
        }
      }
    }
  }
}
