@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import './scss-styles/styles.module.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,h2,h3,h4,li,div,p {
  user-select: none;
}



a {
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-self: center;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.App {
  background-color: $bg-dark;
  overflow-x: hidden;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
}