@import "../../scss-styles/styles.module.scss";

.hero {
  min-height: 100vh;
  max-width: $max-width;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  @media (max-height: 700px) {
    margin-bottom: 5rem;
  }

  .hero__content {
    width: 100%;
    height: 100%;
    padding-left: 2rem;
    @extend %entry-left;

    @media (max-width: 900px) {
      text-align: center;
      padding: 0 1rem;
    }

    .intro {
      color: $text-heading;
      font-size: 5rem;
      margin-bottom: 1rem;

      @media (max-width: 900px) {
        font-size: 4rem;
      }

      @media (max-width: 600px) {
        font-size: 3rem;
      }

      @media (max-width: 400px) {
        font-size: 2rem;
      }

      // @media (max-height: 700px) {
      //   font-size: 2rem;
      // }
    }

    .title {
      color: $text-body;
      font-size: 1.5rem;
      margin-bottom: 4rem;

      @media (max-width: 900px) {
        margin-bottom: 2rem;
        font-size: 1.4rem;
      }

      @media (max-width: 600px) {
        font-size: 1.2rem;
      }

      @media (max-width: 400px) {
        font-size: 1rem;
      }

      // @media (max-height: 700px) {
      //   font-size: 1.4rem;
      // }
    }

    .know-more {
      height: 3rem;
      width: 10rem;
    }
  }

  .profile__container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
    @extend %entry-right;

    @media (max-width: 900px) {
      margin-bottom: 2rem;
    }

    .profile {
      width: 25rem;
      border-radius: 1rem;

      @media (max-width: 900px) {
        width: 17rem;
      }

      @media (max-width: 600px) {
        width: 14rem
      }

      @media (max-width: 400px) {
        width: 12rem;
      }

      // @media (max-height: 800px) {
      //   width: 17rem;
      // }

      // @media (max-height: 700px) and (max-width: 900px) {
      //   width: 10rem;
      // }
    }
  }
}
