@import '../../scss-styles/styles.module.scss';

.projects__section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .projects {
    max-width: $max-width;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .projects__heading__container {
      @extend %entry-top;

      .projects__heading {
          margin-top: 8rem;
          margin-bottom: 7.5rem;
        
          @media (max-width: 900px) {
            margin: 5rem 0;
          }
      }
    }

    .projects__image__container {
        margin-top: 5rem;
        margin-bottom: 5rem;
        @extend %entry-top;

        @media (max-width: 900px) {
          margin-top: 0;
          margin-bottom: 5rem;
        }



        .projects__image {
            width: 40rem;

            @media (max-width: 900px) {
              width: 30rem;
            }

            @media (max-width: 600px) {
              width: 20rem;
            }

            @media (max-width: 400px) {
              width: 17rem;
            }

            @media (max-width: 300px) {
              width: 12rem;
            }
        }
    }
    
  }
}
