@import '../../scss-styles/styles.module.scss';

.project {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 5rem;
    padding: 0 1rem;

    @media (max-width: 900px) {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }


    .overview {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        @extend %entry-left;

        @media (max-width: 900px) {
            align-items: flex-start;
            margin-bottom: 5rem;
        }

        .project__title {
            color: $text-body;
            margin-bottom: 1.5rem;
            

            @media (max-width: 500px) {
                font-size: 1.8rem;
            }

            @media (max-width: 400px) {
                font-size: 1.3rem;
            }
        }

        .project__description {
            color: $text-body;
            margin-bottom: 1.5rem;
            width: 80%;
            font-size: 1rem;


            @media (max-width: 900px) {
                text-align: start;
                width: 40rem;
            }

            @media (max-width: 750px) {
                width: 35rem;
            }

            @media (max-width: 630px) {
                width: 100%;
            }

            @media (max-width: 400px) {
                font-size: .9rem;
            }
            
        }

        .technologies {
            margin-bottom: 1rem;

            .tech-icon {
                width: 2.3rem;
                margin-right: .3rem;
                transition: all .3s ease-out;

                &:hover {
                    transform: scale(1.2);
                }

                @media (max-width: 400px) {
                    width: 2rem;
                }

                @media (max-width: 300px) {
                    width: 1.7rem;
                }
            }
        }

        .project-buttons-container {
            display: flex;

            .project-button {
                margin-right: 1.5rem;
                font-size: 1rem;
                height: 3rem;
                width: 10rem;

                @media (max-width: 600px) {
                    margin-right: .5;
                    height: 3rem;
                    width: 8rem;
                }
            }
        }
    }
    .Tilt-inner {
        @extend %entry-right;

        @media (max-width: 900px) {
            margin-bottom: 1.5rem;
        }

        .image-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            border: 1px solid $primary;
            box-shadow: rgba(83, 109, 254, 0.3) 0px 19px 38px, rgba(83, 109, 254, 0.22) 0px 15px 12px;

            .image {
                width: 40rem;

                @media (max-width: 1100px) {
                    width: 30rem;
                }

                @media (max-width: 900px) {
                    width: 40rem;
                }

                @media (max-width: 750px) {
                    width: 35rem;
                }

                @media (max-width: 630px) {
                    width: 100%;
                }
            }
        }
    }
}